//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { WordCloud } from "@antv/g2plot";
export default {
  name: "SensitiveWords",
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chartHeight: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData(val) {
      if (this.chart) {
        this.chart.changeData(val);
      }
    },
  },
  methods: {
    renderChart() {
      if (this.chart) {
        return;
      }
      this.chart = new WordCloud(this.$refs.chart, {
        data: this.chartData,
        limitInPlot: true,
        wordField: "Word",
        weightField: "Count",
        colorField: "Word",
        wordStyle: {
          fontFamily: "Verdana",
          fontSize: [8, 32],
          rotation: 0,
        },
        // 返回值设置成一个 [0, 1) 区间内的值，
        // 可以让每次渲染的位置相同（前提是每次的宽高一致）。
        random: () => 0.5,
      });

      this.chart.render();
    },
  },
  mounted() {
    this.renderChart();
  },
};
